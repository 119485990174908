body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(75 85 99);
  font-family: "Roboto", sans-serif;
  height: 100%;
  
}

code {
  font-family: "Roboto", sans-serif;
}


/* footer {
  position: fixed;
  left: 0px;
  bottom: 100px;
  height: 30px;
  width: 100%;
  
} */
