p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}