label{
    color:#fff;
}

.blueOne {
    background-color: #136ea5
}

.blueText {
    color: #136ea5
}