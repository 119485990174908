.App {
	text-align: center;
	background-color: rgb(188 188 188);
	height: 100%;
}
* {
	font-family: 'Roboto', sans-serif;
}
.fas[data-count]{
    position:relative;
}
.fas[data-count]:after{
    position: absolute;
    right: -0.75em;
    top: -.75em;
    content: attr(data-count);
    padding: .5em;
    border-radius: 10em;
    line-height: .9em;
    color: white;
    background: rgba(255,0,0,.75);
    text-align: center;
    min-width: 2em;
    font: bold .4em sans-serif;
}

html {
	background-color: rgb(188 188 188);
	height: 100%;
}

p {
	font-size: larger;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.center-col {
	overflow-y: scroll;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
