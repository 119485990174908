.content {
  align-items: left;
}

/* .container {
  display: flex;
  flex-direction: row;
} */

p,
h2,
h1 {
  padding-top: 10px;
}

button {
  margin-top: 10px;
}

.text-left {
  padding-left: 10px;
}

img.expand {
  width: 20em;
  height: 25em;
}

.blueOne {
  background-color: #136ea5
}

.blueText {
  color: #136ea5
}
