.hyperLog {
    width: 500px;
    height: 500px;
}

.blueLeft {
    background-color: #136ea5;
    background-image: linear-gradient(to bottom left, #136ea5, white);
  }

  .blueRight {
    background-color: #136ea5;
    background-image: linear-gradient(to top left, #136ea5, white);
  }

  .blueOne {
    background-color: #136ea5
  }