@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.videoOverlay {
  font-family: "Bebas Neue", cursive;
}

.blueOne {
  background-color: #136ea5
}

.blueText {
  color: #136ea5;
  font-weight: bold;
}
