@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(75 85 99);
  font-family: "Roboto", sans-serif;
  height: 100%;
  
}

code {
  font-family: "Roboto", sans-serif;
}


/* footer {
  position: fixed;
  left: 0px;
  bottom: 100px;
  height: 30px;
  width: 100%;
  
} */

.App {
	text-align: center;
	background-color: rgb(188 188 188);
	height: 100%;
}
* {
	font-family: 'Roboto', sans-serif;
}
.fas[data-count]{
    position:relative;
}
.fas[data-count]:after{
    position: absolute;
    right: -0.75em;
    top: -.75em;
    content: attr(data-count);
    padding: .5em;
    border-radius: 10em;
    line-height: .9em;
    color: white;
    background: rgba(255,0,0,.75);
    text-align: center;
    min-width: 2em;
    font: bold .4em sans-serif;
}

html {
	background-color: rgb(188 188 188);
	height: 100%;
}

p {
	font-size: larger;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.center-col {
	overflow-y: scroll;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.content {
  align-items: left;
}

/* .container {
  display: flex;
  flex-direction: row;
} */

p,
h2,
h1 {
  padding-top: 10px;
}

button {
  margin-top: 10px;
}

.text-left {
  padding-left: 10px;
}

img.expand {
  width: 20em;
  height: 25em;
}

.blueOne {
  background-color: #136ea5
}

.blueText {
  color: #136ea5
}

.hyperLog {
    width: 500px;
    height: 500px;
}

.blueLeft {
    background-color: #136ea5;
    background-image: linear-gradient(to bottom left, #136ea5, white);
  }

  .blueRight {
    background-color: #136ea5;
    background-image: linear-gradient(to top left, #136ea5, white);
  }

  .blueOne {
    background-color: #136ea5
  }
.blueOne {
    background-color: #136ea5
}
details {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: .5em .5em 0;
}

summary {
    font-weight: bold;
    margin: -.5em -.5em 0;
    padding: .5em;
    list-style: none;
}

details[open] {
    padding: .5em;
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: .5em;
}

.blueOne {
    background-color: #136ea5
}
label{
    color:#fff;
}

.blueOne {
    background-color: #136ea5
}

.blueText {
    color: #136ea5
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}


.text-black, label, input, h1{
    color: black;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
.logo {
  width: 40em;
  

  
}


.content{
    padding-right: 600px
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.videoOverlay {
  font-family: "Bebas Neue", cursive;
}

.blueOne {
  background-color: #136ea5
}

.blueText {
  color: #136ea5;
  font-weight: bold;
}

p{
    text-align: left;
}

.bio{
    padding-left: 20px;
}

.container{
    padding-left: 20px;
}
.globalLogo {
  width: 150px;
  height: auto;
}

.center {
  align-items: center;
  width: 50%;
}

.links {
  
  width: 25px;
  height: 25px;
}

.fav{
    top: 40px;
   
}

.button{
    margin-left:50px
}

.container {
  position: relative;
}

.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

.fabIcons{
    margin-top: 10px;
    padding-left: 40px;
    
}

.fab{
  color: #0077b5
}
.newsCard:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 13px 40px -5px rgba(16, 16, 85, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    transition: all ease 200ms;
  }
